<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>excel表格上传(类型xls)</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">

                    <el-form-item label="上传表格" :rules="[{required:true,message:'上传不能为空',trigger: 'blur' }]">
                        <el-upload
                                :on-success="handleVodUploadSuccessone"
                                :on-remove="handleVodRemove"
                                :before-remove="beforeVodRemove"
                                :on-exceed="handleUploadExceed"
                                :file-list="fileList"
                                :action="$api.shipUpload"
                                :headers="upload_headers"
                                :limit="1"
                                class="upload-demo">
                            <el-button size="small" type="primary">上传表格</el-button>
                            <el-tooltip placement="right-end">
                                <div slot="content">
                                    支持3GP、ASF、AVI、DAT、DV、FLV、F4V、<br>
                                    GIF、M2T、M4V、MJ2、MJPEG、MKV、MOV、MP4、<br>
                                    MPE、MPG、MPEG、MTS、OGG、QT、RM、RMVB、<br>
                                    SWF、TS、VOB、WMV、WEBM 等视频格式上传</div>
                                <i class="el-icon-question"/>
                            </el-tooltip>
                        </el-upload>
                    </el-form-item>


                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <!-- <el-button @click="resetForm('info')">重置</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

    </div>
</template>

<script>
    import wangeditor from "@/components/seller/wangeditor.vue"
    export default {
        components: {
            wangeditor,
        },
        props: {},
        data() {
            return {
                edit_id:0,
                info:{

                },
                list:[],
                class_list:[],
                upload_headers:{},
                file_list:[],
                file_list2:[], // element 内置file_list
                dialogVisible:false,// 是否打开预览
                dialogImageUrl:'', // 预览
                disabled:false,
                video: {// 课时对象
                    title: '',
                    sort: 0,
                    free: false,
                    videoSourceId: '',
                    videoOriginalName: ''
                },
                excelbg:''
            };
        },
        watch: {},
        computed: {},
        methods: {
            resetForm:function(e){
                this.$refs[e].resetFields();
            },
            submitForm:function(e){
                let _this = this;

                // 验证表单
                this.$refs[e].validate(function(res){

                    if(res){
                        //  判断是Add 或者 Edit
                        let url = _this.$api.excelimport;

                        // Http 请求
                        _this.$post(url,_this.info).then(function(res){
                            if(res.code == 200){
                                _this.$message.success("上传成功");
                                _this.$router.go(-1);
                            }else{
                                _this.$message.error("上传失败");
                            }
                        });
                    }


                });
            },
            selectChange:function(){
                this.$forceUpdate();
            },
            handlePictureCardPreview:function(file){
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            // 文件删除
            handleRemove:function(file){
                // console.log(file,this.$refs.upload);
                this.$refs.upload.handleRemove(file);
                let index = this.file_list.indexOf(file.url);
                this.file_list.splice(index,1);
            },
            // 设置主图
            setMaster:function(file){
                this.info.goods_master_image = file.response.data;
            },
            // 上传超过限制
            onExceed:function(){
                this.$message.error('展示图片不能超过25个');
            },
            get_master_image:function(file){
                if(file.response != undefined){
                    if(file.response.data == this.info.goods_master_image){
                        return 'display:block';
                    }else{
                        return 'display:none';
                    }
                }
            },
            // 富文本编辑内容变化
            goods_content:function(data){
                this.info.F_TE_XIANGQMS = data;
            },
            // 添加商品
            add_goods:function(){
                let info = this.info;
                info.file_list = this.file_list; // 图片

                // 请求
                this.$post(this.$api.addZhaopzbsp,info).then(res=>{
                    if(res.code == 200){
                        this.$message.success('添加成功');
                        this.$router.go(-1);
                    }else{
                        this.$message.error(res.msg);
                    }
                });
            },
            handleAvatarSuccess(res) {
                this.info.F_VR_TOUX = res.data;
                this.$forceUpdate();
            },
            handleAvatarSuccesss(res) {
                this.info.F_VR_FENGMT = res.data;
                this.$forceUpdate();
            },
            // 获取添加初始化信息
            get_goods_add_info:function(){
                this.$get(this.$api.addZhaopzbsp).then(res=>{
                    if(res.code == 500){
                        this.$message.error(res.msg);
                        this.$router.go(-1);
                    }else{
                        this.list = res.data;

                        /*this.class_list = res.data.integral_class;
                        this.info = res.data.integral_info;
                        this.file_list = res.data.integral_info.goods_images.split(',');
                        res.data.integral_info.goods_images.split(',').forEach(imgRes=>{
                            this.file_list2.push({name:'',url:imgRes});
                        });*/
                    }
                });
            },



            //成功回调
            handleVodUploadSuccessone(response, file, fileList) {
                this.video.videoSourceId = response.data.videoId
                this.video.videoOriginalName = file.name;
                this.info.excelbg = response.data;
                console.log(response)
                console.log(response.data.videoId)
                console.log(file.name)

            },

            //成功回调
            handleVodUploadSuccesstwo(response, file, fileList) {
                this.video.videoSourceId = response.data.videoId
                this.video.videoOriginalName = file.name;
                this.info.F_VR_SHIPTWO = response.data;
                console.log(response)
                console.log(response.data.videoId)
                console.log(file.name)

            },

            //成功回调
            handleVodUploadSuccessthree(response, file, fileList) {
                this.video.videoSourceId = response.data.videoId
                this.video.videoOriginalName = file.name;
                this.info.F_VR_SHIPTHREE = response.data;
                console.log(response)
                console.log(response.data.videoId)
                console.log(file.name)

            },


            //成功回调
            handleVodUploadSuccessfour(response, file, fileList) {
                this.video.videoSourceId = response.data.videoId
                this.video.videoOriginalName = file.name;
                this.info.F_VR_SHIPFOUR = response.data;
                console.log(response)
                console.log(response.data.videoId)
                console.log(file.name)

            },



//修改回掉
            editVideo(videoId) {
                this.dialogVideoFormVisible = true
                video.getVideoInfoById(videoId).then(response => {
                    this.video = response.data.item
                    this.fileList = [{'name': this.video.videoOriginalName}]
                })
            },
//视图上传多于一个视频
            handleUploadExceed(files, fileList) {
                this.$message.warning('想要重新上传视频，请先删除已上传的视频')
            },

            beforeVodRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`)
            },
            handleVodRemove(file, fileList) {
                console.log(file)
                vod.removeById(this.video.videoSourceId).then(response=>{
                    this.$message({
                        type: 'success',
                        message: response.message
                    })
                })
            },


            get_adv_info:function(){
                let _this = this;
                this.$get(this.$api.editZhaopzbsp+this.edit_id).then(function(res){
                    _this.info = res.data.zhaopzbsp;
                    _this.list = res.data;
                    _this.info.F_VR_SHIPONE = res.data.zhaopzbsp.F_VR_SHIPONE;
                    _this.info.F_VR_SHIPTWO = res.data.zhaopzbsp.F_VR_SHIPTWO;
                    _this.info.F_VR_SHIPTHREE = res.data.zhaopzbsp.F_VR_SHIPTHREE;
                    _this.info.F_VR_SHIPFOUR = res.data.zhaopzbsp.F_VR_SHIPFOUR;
                })

            },
            get_adv_list:function(){
                let _this = this;
                this.$get(this.$api.addDuansplb).then(function(res){
                    _this.list = res.data;
                })

            },



        },
        created() {
            this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到

            if(!this.$isEmpty(this.$route.params.adv_position_id)){
                this.info.ap_id = this.$route.params.adv_position_id;
            }

            // 判断是否是编辑
            if(!this.$isEmpty(this.$route.params.id)){
                this.edit_id = this.$route.params.id;
            }

            if(this.edit_id>0){
                this.get_adv_info();
            }else{
                this.get_adv_list();
            }

        },
        mounted() {}
    };
</script>
<style lang="scss" scoped>
    .el-breadcrumb{
        line-height: 32px;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 146px;
        height: 146px;
        line-height: 146px;
        text-align: center;
    }
    .avatar-upload{
        width: 146px;
        height: 146px;
    }

    .is_master{
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(0,0,0,0.5);
        color:#fff;
        width: 164px;
        text-align: center;
        display: none;
    }
    .goods_class_add_left{
        float: left;
        margin-right: 15px;
        // border:1px solid #e1e1e1;
        padding:0 20px;
        border-radius: 5px;
        background: #f1f1f1;
    }
</style>